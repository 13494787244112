<template>
  <panel
    :title="`${transfer.fileNumber} | Profile: ${transfer.profileNumber}`"
    collapsable>
    <data-list :list="data" />
  </panel>
</template>

<script>
export default {
  props: {
    transfer: {
      type: Object,
      required: true
    }
  },

  computed: {
    data () {
      return [
        {
          label: 'File #',
          text: this.transfer.fileNumber
        },
        {
          label: 'Date/Time',
          text: this.$date(this.transfer.createdAt, 'calendar')
        },
        {
          label: 'Profile',
          text: this.transfer.profileNumber
        },
        {
          label: 'Description',
          text: this.transfer.description
        },
        {
          label: 'File',
          html: `<span class="text-ellipsis"><a class="link" target="_blank" href="/files/fileTransfer/${this.transfer.id}/${this.transfer.file}">${this.transfer.file}</a></span>`
        }
      ]
    }
  }
}
</script>
