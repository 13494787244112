<template>
  <div>
    <portal to="actions">
      <div class="flex items-center">
        <el-button
          size="small"
          type="success"
          class="mr-10"
          icon="el-icon-plus"
          circle
          @click="showNewFileTransferModal = true" />
      </div>
    </portal>

    <error-box
      v-if="error"
      :loading="loading"
      @reload="load()" />

    <div v-else>
      <ms-filters
        :filters="appliedFilters"
        :filters-map="filtersMap"
        @apply="applyFilters($event)" />

      <div
        v-if="loading"
        v-loading="loading"
        class="page-loader" />

      <template v-else-if="!!fileTransfers.length">
        <file-transfer-item
          v-for="transfer in fileTransfers"
          :key="transfer.id"
          :transfer="transfer" />
      </template>

      <empty v-else>Empty page</empty>
    </div>

    <new-file-transfer
      v-if="showNewFileTransferModal"
      @close="showNewFileTransferModal = false" />
  </div>
</template>

<script>
import navigation from './navigation'

import NewFileTransfer from './NewFileTransfer'
import Empty from '@/components/common/ui/Empty'
import ErrorBox from '@/components/common/ui/ErrorBox'
import FileTransferItem from '@/components/support/FileTransferItem'

export default {
  components: {
    Empty,
    ErrorBox,
    NewFileTransfer,
    FileTransferItem
  },

  data () {
    return {
      appliedFilters: null,
      showNewFileTransferModal: false,

      error: null,
      loading: false
    }
  },

  computed: {
    filtersMap () {
      return [
        {
          key: 'date',
          label: 'Date Range',
          type: 'range_datepicker'
        },
        {
          key: 'number',
          label: 'File Number',
          type: 'input'
        },
        {
          key: 'profile',
          label: 'Profile',
          type: 'input'
        }
      ]
    },

    user () {
      return this.$store.state.app.user
    },

    fileTransfers () {
      let files = this.$store.state.fileTransfer.data || []
      if (this.appliedFilters && !!Object.values(this.appliedFilters).filter(n => n).length) {
        if (this.appliedFilters.number) {
          files = files.filter(file => {
            return file.fileNumber.includes(this.appliedFilters.number)
          })
        }

        if (this.appliedFilters.profile) {
          files = files.filter(file => { return file.profileNumber?.includes(this.appliedFilters.profile) })
        }
      }

      return files
    },

    fileTransfersCount () {
      return this.fileTransfers.length
    }
  },

  watch: {
    fileTransfersCount () {
      this.setPageTitle()
    },

    'appliedFilters.date.from' () {
      this.load()
    },

    'appliedFilters.date.to' () {
      this.load()
    }
  },

  created () {
    this.setPageTitle()

    this.$store.commit('page/setTabs', navigation)

    this.load()
  },

  methods: {
    applyFilters (filters) {
      this.appliedFilters = filters
    },

    setPageTitle () {
      this.$store.commit('page/setPageInfo', {
        title: `File Transfer (${this.fileTransfersCount})`,
        back: { name: 'Support' }
      })
    },

    async load () {
      if (this.loading) return
      this.error = false
      this.loading = true
      try {
        const params = {
          profile: this.user.regId
        }

        if (this.appliedFilters && Object.values(this.appliedFilters).filter(i => i).length > 0) {
          if (this.appliedFilters.date?.from) { params.createdAt = ['>=', this.appliedFilters.date.from + ' 00:00:00', 'createdAt'] }
          if (this.appliedFilters.date?.to) { params.createdAt = ['<=', this.appliedFilters.date.to + ' 23:59:59', 'createdAt'] }
        }

        await this.$store.dispatch('fileTransfer/load', { params })
      } catch {
        this.error = true
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
