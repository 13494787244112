<template>
  <ms-drawer
    title="New File Transfer"
    class="gray"
    @close="close()">
    <ms-select
      v-model="form.profile"
      label="Profile"
      :options="accountOptions"
      showAsText />

    <ms-textarea
      v-model="form.description"
      label="Description"
      :validator="$v.form.description"
      :rows="3" />

    <ms-upload-input
      v-model="form.file"
      label="File Attachment"
      :validator="$v.form.file" />

    <template slot="footer">
      <div class="flex-buttons">
        <el-button class="max-w-1-3" type="default" round @click="close()">
          Cancel
        </el-button>

        <el-button
          type="success"
          class="gradient"
          @click="submit()"
          round>
          Create
        </el-button>
      </div>
    </template>
  </ms-drawer>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        profile: null,
        description: null,
        file: null
      },

      saving: false
    }
  },

  computed: {
    user () {
      return this.$store.state.app.user
    },

    accountOptions () {
      if (!this.user?.registration) return []

      return [
        {
          id: this.user.registration.id,
          text: `${this.user.registration.profileNumber}-${this.user.registration.name}`
        }
      ]
    }
  },

  watch: {
    user: {
      handler () {
        this.form.profile = this.user?.registration?.id
      },

      immediate: true
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    async submit () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.saving = true
      try {
        const payload = {
          id: 'new',
          action: 'Submit',
          payload: {
            profile: this.form.profile,
            description: this.form.description
          }
        }
        const transfer = await this.$store.dispatch('fileTransfer/action', payload)

        if (this.form.file) await this.$store.dispatch('fileTransfer/upload', { id: transfer.item.id, file: this.form.file })

        this.$message.success('File Transfer created successfully.')
        this.close()
      } catch (e) {
        this.$notify.error(window.errorMessage)
      } finally {
        this.saving = false
      }
    }
  },

  validations () {
    let rules = {}
    rules = {
      form: {
        profile: { required },
        description: { required },
        file: { required }
      }
    }

    return rules
  }
}
</script>
